import React from 'react';
import PageLayout from '../../components/Layout/Page';
import EventsShell from '../../components/Event/Shell';

const EventsPage = () => {
  return (
    <PageLayout
      title="Campus events"
      excerpt="Join us at one of our weekend campus events"
    >
      <EventsShell filterFn={event => event.on_campus_event} />
    </PageLayout>
  );
};

export default EventsPage;
